/**
 * loading：获取初始化列表的接口 设置为true
 */

export default {
  namespaced: true,
  state: {
    loading: false, // 控制内容区域加载 页面初始化时调用
    pageLoading: false, // 控制分页时候触发的加载
    cardLoading: false, // el-card的加载
    tableLoading: false, // 表格加载
    buttonLoading: false, // 按钮加载
    heightLightElement: null,
  },
  mutations: {
    ////////// loading //////////
    SET_START_LOADING(state) {
      state.loading = true;
    },
    SET_CANCEL_LOADING(state) {
      state.loading = false;
    },
    ////////// loading //////////

    ////////// pageLoading //////////
    SET_START_PAGELOADING(state) {
      state.pageLoading = true;
    },
    SET_CANCEL_PAGELOADING(state) {
      state.pageLoading = false;
    }, 
    ////////// pageLoading //////////
    
    ////////// cardLoading //////////
    SET_START_CARDLOADING(state) {
      state.cardLoading = true;
    },
    SET_CANCEL_CARDLOADING(state) {
      state.cardLoading = false;
    },
    ////////// cardLoading //////////

    ////////// tableLoading //////////
    SET_START_TABLELOADING(state) {
      state.tableLoading = true;
    },
    SET_CANCEL_TABLELOADING(state) {
      state.tableLoading = false;
    },
    ////////// tableLoading //////////

    ////////// buttonLoading //////////
    SET_START_BUTTONLOADING(state) {
      state.buttonLoading = true;
    },
    SET_CANCEL_BUTTONLOADING(state) {
      state.buttonLoading = false;
    },
    ////////// buttonLoading //////////

    SET_HEIGHT_LIGHT_ELE(state, element) {
      state.heightLightElement = element;
    }
  },
  actions: {},
}