import en from './en'
import zh from './zh'
import pl from './pl'
import de from './de'
export default {
  en_US: en,
  zh_CN: zh,
  pl_PL: pl,
  de_DE: de,
  zh,
  en,
  pl
}