import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import { router } from './router';
import store from './store';
// 引入windicss
import 'windi.css';
// 引入饿了么
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { copy } from '@/directive/common.directive'

// 引入il18国际化
import i18n from './i18n/index'//国际化

import VueGridLayout from 'vue-grid-layout'

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.directive('copy', copy);

// 引入路由守卫文件
import '@/router/permission';
// 引入nprogress
import 'nprogress/nprogress.css';

app.use(i18n).use(store).use(router).use(VueGridLayout).mount('#app')
export default app;