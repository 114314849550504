import { createStore } from 'vuex';
import common from './modules/common';
import user from './modules/user';
import device from './modules/device';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common,
    user,
    device
  }
})
