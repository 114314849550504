export default {
  namespaced: true,
  state: {
    productList: [], // 所有产品列表
  },
  mutations: {
    SAVE_PRODUCTLIST(state, value) { // 保存产品列表
      state.productList = value
    }
  },
  actions: {
    save_productList({commit}, value) { // 保存产品列表
      commit('SAVE_PRODUCTLIST', value)
    },
    
  }
}