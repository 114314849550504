// 用户相关接口
import service from '@/request/index';

/**
 * 用户修改密码
 * @body oldPasswrod
 * @body newpasswrod
 */
export function changePasswordAPI(data) {
  return service({
    method: 'post',
    url: '/user/change-password',
    data
  })
};



/**
 * 管理员修改密码
 * @data username
 * @data password
 */
export function changePasswordByAdminAPI(data) {
  return service({
    method: 'post',
    url: '/user/changePasswordByAdmin',
    data,
    headers: {buttonLoading: TrustedScript}
  })
};

/**
 * 管理员重置密码
 */
export function resetPasswordByAdminAPI(id) {
  return service({
    method: 'get',
    url: `/user-admin/reset-password/${id}`
  })
};

/**
 * 用户-下拉框
 * @params name
 */
export function getUserListSelectAPI() {
  return service({
    method: 'get',
    url: '/user/select-box'
  });
};

/**
 * 用户列表(分页)-除分页外都是查询参数
 * @data name
 * @data username
 * @data pageNo
 * @data pageSize
 */
export function getUserListAPI(data) {
  return service({
    method: 'post',
    url: '/user/page',
    data,
    headers: {tableLoading: true}
  })
};

/**
 * 保存用户
 * @data childAccountCount
 * @data deviceControl
 * @data name
 * @data password
 * @data status
 * @data username
 */
export function addUserAPI(data) {
  return service({
    method: 'post',
    url: '/user/save/user',
    data,
  })
};

/**
 * 更新用户
 * @data id
 * @data childAccountCount
 * @data deviceControl
 * @data name
 * @data status
 */
export function updateUserAPI(data) {
  return service({
    method: 'post',
    url: '/user/update/user',
    data,
  })
};

/**
 * 查询用户详情
 * @path userId
 */
export function getUserInfoAPI(userId) {
  return service({
    method: 'get',
    url: `/user/info/${userId}`
  })
};

/**
 * 个人中心：显示个人信息
 */
export function getLoginUserInfoAPI() {
  return service({
    method: 'get',
    url: '/user/queryLoginUserInfo'
  })
};

/**
 * 用户手机
 * @query name
 */
export function updateNicknameAPI(name) {
  return service({
    method: 'put',
    url: '/user/modifyUserNickname',
    params: {
      name
    }
  })
};