// 权限系列的路由
import i18n from '@/i18n/index';

const { t }= i18n.global;
const systemRoutes = [
  {
    path: '/system',
    name: '/system',
    meta: {title: t('routeName.system')},
    component: () => import('@/views/system/index.vue'),
    children: [
      // 登录日志
      {
        path: '/system/loginLogs',
        name: '/system/loginLogs',
        meta: {title: t('routeName.loginLogs'), keepAlive: true},
        component: () => import('@/views/system/LoginLogs/LoginLogs.vue'),
        children: []
      },
      // 更新日志
      {
        path: '/system/updateLogs',
        name: '/system/updateLogs',
        meta: {title: t('routeName.updateLogs'), keepAlive: true},
        component: () => import('@/views/system/UpdateLogs/UpdateLogs.vue')
      },
      // 设备日志
      {
        path: '/system/deviceLogs',
        name: '/system/deviceLogs',
        meta: {title: t('routeName.deviceLogs'), keepAlive: true},
        component: () => import('@/views/system/DeviceLogs/DeviceLogs.vue')
      }
    ],
  }
];

systemRoutes[0].children.forEach( item => item.meta.fatherPath = '/system');


export default systemRoutes;