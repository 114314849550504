import { ElMessage } from 'element-plus';
import i18n from '@/i18n/index';
const { t }= i18n.global;

export const copy = {
  beforeMount(el, binding) {
    el.tagetContent = binding.value;
    el.addEventListener('click', (e) => {
      e.stopPropagation();
      const textarea = document.createElement('textarea');
      textarea.readOnly = 'readonly';
      textarea.style.position = 'fixed';
      textarea.style.top = '-99999999999px';
      textarea.value = el.tagetContent;
      document.body.appendChild(textarea);
      textarea.select();
      const res = document.execCommand('Copy');
      res && ElMessage.success(t('common.copyText') + el.tagetContent);
      document.body.removeChild(textarea);
    })
  },
  updated(el, binding) {
    el.tagetContent = binding.value
  },
  unmounted(el) {
    el.removeEventListener('click', null);
  }
}