// 菜单相关接口
import service from '@/request/index';

const baseURL = '/menu';

/**
 * 登陆后获取当前用户所拥有权限菜单树
 * @query website_type USER 
 */
export function getMenuAPI() {
  return service({
    method: 'get',
    url: `${baseURL}/get-menus`,
    params: {
      website_type: 'USER'
    }
  })
}

// 下列废弃转移到管理端

/**
 * 获取所有菜单树
 */
/* export function getAllMenuAPI() {
  return service({
    method: 'get',
    url: `${baseURL}/getAllMenuTree`,
    headers: { loading: true }
  })
} */

/**
 * 更新菜单顺序
 * @data list
 */
/* export function updateMenuOrderAPI(data) {
  return service({
    method: 'put',
    url: `${baseURL}/updateMenuOrder`,
    data
  })
} */

/**
 * 获取1级菜单列表
 */
/* export function getRootMenuListAPI() {
  return service({
    method: 'get',
    url: `${baseURL}/getRootMenuList`
  })
} */

/**
 * 修改/添加菜单
 * @data englishName
 * @data icon
 * @data id
 * @data name
 * @data order
 * @data parentId
 * @data url
 * @data vueUrl 
 * */
/* export function addMenuAPI(data) {
  return service({
    method: 'post',
    url: `${baseURL}/addMenu`,
    data
  })
} */
/* export function updateMenuAPI(data) {
  return service({
    method: 'put',
    url: `${baseURL}/updateMenu`,
    data
  });
} */

/**
 * 删除菜单
 * @param menuId
 */
/* export function deleteMenuAPI(params) {
  return service({
    method: 'delete',
    url: `${baseURL}/delMenu`,
    params
  })
} */

/**
 * 根据角色生成菜单树
 * @params roleId
 */
/* export function getMenuTreeBaseRoleAPI(params) {
  return service({
    method: 'get',
    url: `${baseURL}/getMenuByRoleId`,
    params
  })
} */