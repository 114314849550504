// 权限系列的路由
// 必须加个重定向
import i18n from '@/i18n/index';

const { t }= i18n.global;
const permissionRoutes = [
  {
    path: '/iot/permission',
    name: '/iot/permission',
    meta: {title: t('routeName.permission')},
    component: () => import('@/views/permission/index.vue'),
    children: [
      // 菜单列表
      /* {
        path: '/permission/menuList',
        name: '/permission/menuList',
        meta: {title: '菜单列表', keepAlive: true},
        component: () => import('@/views/permission/menuList/menuList.vue')
      }, */
      // 角色权限管理
    /*   {
        path: '/permission/rolePermission',
        name: '/permission/rolePermission',
        meta: {title: '角色权限管理', keepAlive: true},
        component: () => import('@/views/permission/rolePermission/rolePermission.vue')
      }, */
      // 公司列表
      /* {
        path: '/permission/corporation',
        name: '/permission/corporation',
        meta: {title: '公司列表', keepAlive: true},
        component: () => import('@/views/permission/corporation/corporation.vue')
      }, */
      // 公司列表详情
      /* {
        path: '/permission/corporationDetail/:id',
        name: '/permission/corporationDetail',
        meta: {title: '公司详情', belongPath: '/permission/corporation'},
        component: () => import('@/views/permission/corporation/corporationDetail.vue')
      }, */
      // 公司管理
      {
        path: '/permission/corporationManage',
        name: '/permission/corporationManage',
        meta: {title: t('routeName.corporationManage'), keepAlive: true},
        component: () => import('@/views/permission/corporationManage/corporationManage.vue')
      },
      // {
      //   path: '/permission/corporationDetail/:id',
      //   name: '/permission/corporationDetail',
      //   meta: {title: '公司管理详情', keepAlive: false, belongPath: '/permission/corporationManage'},
      //   component: () => import('@/views/permission/corporationManage/corporationDetail.vue'),
      // },
      // 用户管理
      /* {
        path: '/permission/userManage',
        name: '/permission/userManage',
        meta: {title: '用户管理', keepAlive: true},
        component: () => import('@/views/permission/userManage/userManage.vue'),
      }, */
      // 用户详情
      /* {
        path: '/permission/userDetail/:id',
        name: '/permission/userDetail',
        meta: {title: '用户详情', keepAlive: false, belongPath: '/permission/userManage'},
        component: () => import('@/views/permission/userManage/userDetail')
      }, */
      // 子账号管理
      {
        path: '/permission/subAccout',
        name: '/permission/subAccout',
        meta: {title: t('routeName.subAccout'), keepAlive: true},
        component: () => import('@/views/permission/subAccout/subAccout.vue')
      },
      {
        path: '/permission/subAccoutDetail/:id',
        name: '/permission/subAccoutDetail',
        meta: {title: t('routeName.subAccoutDetail'), keepAlive: false, belongPath: '/permission/subAccout'},
        component: () => import('@/views/permission/subAccout/subAccoutDetail.vue')
      },
      // 经销商管理
      {
        path: '/permission/dealer',
        name: '/permission/dealer',
        meta: {title: t('routeName.dealer'), keepAlive: true},
        component: () => import('@/views/permission/dealer/dealer.vue')
      },
      {
        path: '/permission/dealerDetail/:id',
        name: '/permission/dealerDetail',
        meta: {title: t('routeName.dealerDetail'), keepAlive: false, belongPath: '/permission/dealer'},
        component: () => import('@/views/permission/dealer/dealerDetail.vue')
      }
    ],
  }
];

permissionRoutes[0].children.forEach( item => item.meta.fatherPath = '/iot/permission');


export default permissionRoutes;