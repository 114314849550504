
import { ElNotification, ElMessageBox } from 'element-plus';
import nprogress from 'nprogress';
import dayjs from 'dayjs';
import i18n from '@/i18n/index';
const { t }= i18n.global

// 封装element通知
export function toast(message, type, dangerouslyUseHTMLString = false) {
  ElNotification({
    type, // success/warning/info/error
    message,
    duration: 10000,
    dangerouslyUseHTMLString
  });
};

// 封装element消息弹出框
export function showModel( content = t('common.tipContent'), type = "warning", title = "") {
  return ElMessageBox.confirm(
    content,
    title,
    {
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel'),
      type,
    }
  )
};

// 封装element消息弹出框 alert 
export function showModelAlert(content, title, buttonText, callback) {
  return ElMessageBox.alert(content, title, {
    confirmButtonText: buttonText,
    callback
  })
};

// 全局进度条
export function showFullLoading() {
  nprogress.start();
};
export function hideFullLoading() {
  nprogress.done();
};

// 语言设置
export class LangSetting {
  // 获取本地存储语言
  getLang() {
    return localStorage.getItem('lang');
  };
  // 保存设置语言
  setLang (value) {
    localStorage.setItem('lang', value)
  };
  // 初始化语言
  initLang() {
    let lang = this.getLang();
    lang=='zh' ? this.setLang('zh_CN'): lang=='en' ? this.setLang('en_US'): lang=='pl' ? this.setLang('pl_PL') : '' // 更改多语言缓存内容
    // 海外定制环境默认英语
    if (process.env.NODE_ENV==='overseas') {
      this.setLang('en_US');
    }else if(!lang) {
      const webLang = navigator.language; // 浏览器语言
      lang = webLang.startsWith('zh') ? 'zh_CN' : 'en_US';
      this.setLang(lang);
    }
  }
};

export const getSenveDayList = (dayNum = 7) => {
  let sevenDayList = [];
  const todyTime = new Date().getTime();
  const oneDayTime = 1000 * 60 * 60 * 24;
  for(let i= 0; i < dayNum; i++) {
    sevenDayList.unshift(dayjs(todyTime - i * oneDayTime).format('YYYY-MM-DD'));
  };
  return sevenDayList
};

export const getCurrentMonthDayList = (month) => {
  let dayInMonth = dayjs(month).daysInMonth();
  let dayInMonthList = [];
  for( let i = 1 ; i <= dayInMonth ; i++) {
    dayInMonthList.push(i)
  }
  return dayInMonthList
};

export const timestampToTime = (timestamp) => {
  // 时间戳为10位需*1000，时间戳为13位不需乘1000
  var date = new Date(timestamp);
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
  var s = (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
  return Y + M + D + h + m + s;
}