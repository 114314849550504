import i18n from '@/i18n/index';

const { t }= i18n.global;
const cloudRoutes = [
  { // 未开通云开发服务则不显示
    path: '/iot/cloud',
    name: '/iot/cloud',
    meta: {title: 'cloud'},
    component: () => import('@/views/cloud/index.vue'),
    children: [
      // 客户管理
      {
        path: '/cloud/client',
        name: '/cloud/client',
        meta: {title: t('clientManagement.clientManagement'), keepAlive: true},
        component: () => import('@/views/cloud/client/client.vue')
      },
      // API管理
      {
        path: '/cloud/api',
        name: '/cloud/api',
        meta: {title: t('apiManagement.apiManagement'), keepAlive: true},
        component: () => import('@/views/cloud/api/api.vue')
      },
      // API请求统计
      {
        path: '/cloud/totalApiAnalysis',
        name: '/cloud/totalApiAnalysis',
        meta: {title: t('apiManagement.apiManagement'), keepAlive: true},
        component: () => import('@/views/cloud/totalApiAnalysis/index.vue')
      },
      // API请求详情
      {
        path: '/cloud/detailApiAnalysis',
        name: '/cloud/detailApiAnalysis',
        meta: {title: t('apiManagement.apiManagement'), keepAlive: true},
        component: () => import('@/views/cloud/detailApiAnalysis/index.vue')
      },
      // 设备消息统计
      {
        path: '/cloud/totalMsgAnalysis',
        name: '/cloud/totalMsgAnalysis',
        meta: {title: t('apiManagement.apiManagement'), keepAlive: true},
        component: () => import('@/views/cloud/totalMsgAnalysis/index.vue')
      },
    ],
  }
];

cloudRoutes[0].children.forEach( item => item.meta.fatherPath = '/iot/cloud');


export default cloudRoutes;