import service from '@/request/index';
const baseURL = '/user-cloud-api';

/**
 * 获取当前登录用户的云开发密钥信息
 */
export function getCloudClientInfoAPI() {
    return service({
        method: 'get',
        url: `${baseURL}/cloud-client-info`
    })
};

/**
 * 获取当前登录用户的云开发密钥信息
 * @data pageNo
 * @data pageSize
 * @data apiAddr
 * @data apiName
 */
export function pageApiListAPI(data) {
    return service({
        method: 'post',
        url: `${baseURL}/cloud-api-page`,
        data,
        headers: { tableLoading: true }
    })
};

/**
 * 获取每个API调用的情况
 * @data clientId
 * @data startTime
 * @data endTime
 * @data pageNo
 * @data pageSize
 */
export function listApiInfoAPI(data) {
    return service({
        method: 'post',
        url: `${baseURL}/list-api-statistics`,
        data,
        headers: { tableLoading: true }
    })
};

/**
 * 获取API调用统计数据
 * @data clientId
 * @data startTime
 * @data endTime
 * @data step
 */
export function getTotalApiInfoAPI(data) {
    return service({
        method: 'post',
        url: `${baseURL}/total-api-analysis`,
        data
    })
};

/**
 * 客户获取设备数据统计
 * @data clientId
 * @data startTime
 * @data endTime
 * @data step
 */
export function getTotalMsgInfoAPI(data) {
    return service({
        method: 'post',
        url: `${baseURL}/total-msg-analysis`,
        data
    })
};

/**
 * 客户密钥重置
 * @data clientId
 */
export function resetCloudClientAPI(data) {
    return service({
        method: 'post',
        url: `${baseURL}/cloud-client/reset`,
        data
    })
};

