// 公司/组织 接口
import service from '@/request/index';

/**
 * 根据当前登录用户查询公司信息
 */
export function userBelongCorporationInfoAPI() {
  return service({
    method: 'get',
    url: '/corporation/get-login-user-corp-info'
  })
}