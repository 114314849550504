import { createRouter, createWebHashHistory } from 'vue-router';
import frontRoutes from './routes/front';
import deviceRoutes from './routes/device';
import afterSaleRoutes from './routes/afterSale';
import permissionRoutes from './routes/permission';
import systemRoutes from './routes/system';
import cloudRoutes from './routes/cloud';
import i18n from '@/i18n/index';

const { t }= i18n.global;
const routes = [
  {
    path: '/',
    name: 'Admin',
    redirect: '/index',
    meta: { title: t('routeName.homePage')},
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/normalUser',
        name: '/normalUser',
        component: () => import('@/views/normalUser/index.vue'),
        children: [
          // 普通用户更新日志
          {
            path: 'updateLog',
            name: 'updateLog',
            meta: {title: t('routeName.updateLogs'), keepAlive: false},
            component: () => import('@/views/normalUser/updateLog/updateLog.vue')
          },
          // 个人中心
          {
            path: 'personCenter',
            name: 'personCenter',
            meta: {title: t('routeName.personalCenter'), keepAlive: false},
            component: () => import('@/views/normalUser/personCenter/personCenter')
          },
          // 404页面
          {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: () => import('@/views/404.vue')
          }
        ]
      }
    ]
  },
  // 登录页面
  {
    path: '/login',
    name: '/login',
    meta: { title: t('routeName.login')},
    component: () => import('@/views/auth/login.vue')
  },
  // 注册页面
  {
    path: '/c/r/:l',
    name: '/c/r',
    meta: { title: t('register.registerTitle')},
    component: () => import('@/views/auth/register.vue')
  }
  // 忘记密码页面，暂时不加该功能
  // {
  //   path: '/resetPsw',
  //   name: '/resetPsw',
  //   component: () => import('@/views/auth/resetPsw.vue')
  // },
];

// 定义动态路由
const asyncRoutes = [
  ...frontRoutes,
  ...deviceRoutes,
  ...afterSaleRoutes,
  ...permissionRoutes,
  ...systemRoutes,
  ...cloudRoutes
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// 动态添加路由
export function addRoutes(menus) {
  let hasNewRoutes = false;
  const findAndAddRoutesBuMenus = (arr) => {
    arr.forEach( item => {
      // 将接口返回的菜单列表与定义的动态路由进行比对
      const isExitItem = asyncRoutes.find( asyncItem => asyncItem.path === item.vueUrl );
      // isExitItem存在，且路由中没有定义过 则 添加该路由
      if(isExitItem && !router.hasRoute(isExitItem.path)) {
        router.addRoute('Admin', isExitItem);
        hasNewRoutes = true;
      }

      // 查找该项下是否有子路由，有的话再次进行此函数(递归)
      if(item.children && item.children.children > 0) {
        findAndAddRoutesBuMenus(item.children);
      }
    });
  }
  findAndAddRoutesBuMenus(menus);
  return hasNewRoutes;
}
