// 首页路由
import i18n from '@/i18n/index';

const { t }= i18n.global;
const frontRoutes = [
  {
    path: '/index',
    name: '/index',
    meta: {title: t('routeName.homePage')},
    component: () => import('@/views/front/index.vue')
  }
];

frontRoutes.forEach( item => item.meta.fatherPath = '');

export default frontRoutes;