import { createI18n } from 'vue-i18n/index.mjs'
import messages from './langs/index';
//从localStorage中拿到用户的语言选择，如果没有，那默认中文。
const i18n = createI18n({
  // legacy: false,
  globalInjection: true, // 全局模式，可以直接使用 $t
  locale: localStorage.lang || 'zh_CN',
  messages,
});

export default i18n;
