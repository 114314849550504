// 处理cookie操作 将token及用户账号密码存储起来
import Cookies from 'js-cookie';

const tokenKey = 'admin-token';

/*************** 存储token start ******************/ 
export function getToken() {
  return Cookies.get(tokenKey);
};

export function setToken(token) {
  return Cookies.set(tokenKey, token, { expires: 7 });
};

export function removeToken() {
  return Cookies.remove(tokenKey);
};

/*************** 存储token end ******************/ 

/*************** 存储账号密码 start ******************/ 
export function setUsername(username) {
  localStorage.setItem('username', username)
};
export function setPassword(password) {
  localStorage.setItem('password', password)
};

export function removeUsername() {
  localStorage.removeItem('username')
};
export function removePassword() {
  localStorage.removeItem('password')
};

export function getUsername() {
  return localStorage.getItem('username')
};
export function getPassword() {
  return localStorage.getItem('password')
};
/*************** 存储账号密码 end ******************/ 