import { router, addRoutes } from './index';
import store from '@/store/index';
import { toast, showFullLoading, hideFullLoading } from '@/utils/common';
import { getToken } from '@/utils/auth';
import i18n from '@/i18n/index';
const { t }= i18n.global;

// 防止重复性加载getInfo
let hasGetInfo = false;
// 全局前置守卫
router.beforeEach( async (to, from, next) => {
  // 跳转前开启进度条
  showFullLoading();
  // 获取token
  const token = getToken();

  // 没登录可以前往注册页面
  if(!token && to.path.indexOf('/c/r') == 0 && to.query.p) {
    return next();
  }

  // 如果没登录且要去往的页面还不是登录页，强制跳转回登录页
  if(!token && to.path !== '/login') {
    toast(t('login.pleaseLogin'), 'error');
    return next({path: '/login'})
  }
  
  // 防止重复登录
  if(token && to.path === '/login') {
    toast(t('loginAgain') ,'error');
    return next({ path: from.path && (from.path.indexOf('/c/r')!=0 || !from.query.p) ? from.path : '/' }); 
  }

  // 如果用户登录了，自动获取当前用户的信息及菜单权限
  // 添加hasGetInfo字段是为了防止每次切换路由都发生 请求菜单与用户信息 
  let hasNewRoutes = null;
  if(token && !hasGetInfo) {
    hasGetInfo = true;
    await store.dispatch('user/SET_USER_INFO');
    hasNewRoutes = addRoutes(store.state.user.navList);
  }

  // 设置页面标题
  let title = (to.meta.title ?  to.meta.title + '-' : '' ) + (process.env.NODE_ENV==='overseas'? 'Sendo IoT' : t('routeName.btIotPlatform'));
  document.title = title;

  let redirectRoute = '';
  store.state.user.navList.forEach( item => {
    if(item.vueUrl === to.path) {
      item.children.length ? redirectRoute = item.children[0].vueUrl : '';
    }
  })

  if(hasNewRoutes) {
    next(to.fullPath);
  } else {
    if(store.state.user.navList.map( item => item.vueUrl).includes(to.path) && redirectRoute) {
      return next(redirectRoute)
    }
    next();
  }
});

// 全局后置守卫
router.afterEach((to, from) => {
  // 跳转成功关闭进度条
  hideFullLoading();
});
