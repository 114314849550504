// 售后系列的路由
import i18n from '@/i18n/index';

const { t }= i18n.global;
const afterSaleRoutes = [
  {
    path: '/iot/afterSales',
    name: '/iot/afterSales',
    meta: {title: t('routeName.afterSales')},
    component: () => import('@/views/afterSale/index.vue'),
    children: [

    ],
  }
];

afterSaleRoutes[0].children.forEach( item => item.meta.fatherPath = '/iot/afterSales');

export default afterSaleRoutes;