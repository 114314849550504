// 设备系列的路由
import i18n from '@/i18n/index';

const { t }= i18n.global;
const deviceRoutes = [
  {
    path: '/iot/device',
    name: '/iot/device',
    meta: {title: t('routeName.device')},
    component: () => import('@/views/device/index.vue'),
    children: [
      // 设备列表路由
      {
        path: '/iot/deviceList',
        name: '/iot/deviceList',
        meta: {title: t('routeName.deviceList'), keepAlive: true},
        component: () => import('@/views/device/deviceList/deviceList.vue'),
        children: []
      },
      // 设备列表 设备详情路由
      {
        path: '/iot/deviceDetail/:id/:unit?',
        name: '/iot/deviceDetail',
        meta: {title: t('routeName.deviceDetail'), belongPath: '/iot/deviceList'}, // belongPath是哪个页面的详情
        component: () => import('@/views/device/deviceList/deviceDetail.vue')
      },
      // 产品列表路由
      {
        path: '/iot/productList',
        name: '/iot/productList',
        meta: {title: t('routeName.productList'), keepAlive: true},
        component: () => import('@/views/device/productList/productList.vue')
      },
      // 产品列表 产品详情路由
      {
        path: '/iot/productiDetail/:id',
        name: '/iot/productiDetail',
        meta: {title: t('routeName.productiDetail'), belongPath: '/iot/productList'},
        component: () => import('@/views/device/productList/productDetail.vue')
      },
      // 微信小程序配置
      {
        path: '/device/miniProgramConfig',
        name: '/device/miniProgramConfig',
        meta: {title: t('routeName.miniProgramConfig'), keepAlive: true},
        component: () => import('@/views/device/miniProgramConfig/MiniProgramConfig.vue')
      },
      // 故障管理
      {
        path: '/device/errorManage',
        name: '/device/errorManage',
        meta: {title: t('routeName.errorManage'), keepAlive: true},
        component: () => import('@/views/device/errorManage/errorManage.vue')
      },
      // 工程管理
      {
        path: '/device/engineerManage',
        name: '/device/engineerManage',
        meta: {title: t('routeName.engineerManage'), keepAlive: true},
        component: () => import('@/views/device/engineerManage/engineerManage.vue')
      },
      // 工程管理 工程详情路由
      {
        path: '/device/engineerDetail/:id',
        name: '/device/engineerDetail',
        meta: {title: t('routeName.engineerDetail'), belongPath: '/device/engineerManage'},
        component: () => import('@/views/device/engineerManage/engineerDetail.vue')
      },
      // 设备地图
      {
        path: '/device/map',
        name: '/device/map',
        meta: {title: t('routeName.map'), keepAlive: true},
        component: () => import('@/views/device/map/map.vue')
      },
      // 设备归属
      {
        path: '/device/ownership',
        name: '/device/ownership',
        meta: {title: t('routeName.ownership'), keepAlive: true},
        component: () => import('@/views/device/ownership/ownership.vue')
      },
      // 设备审核
      {
        path: '/device/audit',
        name: '/device/audit',
        meta: {title: t('deviceAudit.deviceAudit'), keepAlive: true},
        component: () => import('@/views/device/audit/audit.vue')
      },
      {
        path: '/device/auditDetail/:id',
        name: '/device/auditDetail',
        meta: {title: t('deviceAudit.deviceAudit'), belongPath: '/device/audit'},
        component: () => import('@/views/device/audit//auditDetail.vue')
      }
    ],
  }
];

deviceRoutes[0].children.forEach( item => item.meta.fatherPath = '/iot/device');

export default deviceRoutes;