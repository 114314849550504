import { reactive, toRefs } from '@vue/reactivity';
import zh from 'element-plus/lib/locale/lang/zh-cn';
import en from 'element-plus/lib/locale/lang/en';
import pl from 'element-plus/lib/locale/lang/pl';
import de from 'element-plus/lib/locale/lang/de';
import { LangSetting } from '@/utils/common';
import { getCurrentInstance, nextTick, provide } from 'vue-demi';
let langSetting = new LangSetting();
export default {
  setup() {
    const {
      ctx
    } = getCurrentInstance();
    const state = reactive({
      locale: localStorage.lang === 'en_US' || localStorage.lang === undefined ? en : localStorage.lang === 'zh_CN' ? zh : localStorage.lang === 'pl_PL' ? pl : de,
      isRouterAlive: true
    });
    const reload = () => {
      state.isRouterAlive = false;
      nextTick(() => {
        state.isRouterAlive = true;
        ctx.$i18n.locale = localStorage.lang;
        state.locale = localStorage.lang === 'en_US' || localStorage.lang === undefined ? en : localStorage.lang === 'zh_CN' ? zh : localStorage.lang === 'pl_PL' ? pl : de;
      });
    };
    provide('reload', reload);
    langSetting.initLang();
    return {
      ...toRefs(state)
    };
  }
};